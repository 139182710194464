<template>
  <div>
    <div
      class="bg-white rounded-3xl flex items-center justify-between p-4 mx-10 my-3 border border-gray-100 shadow-lg"
    >
      <h3 :class="{ 'text-rose': isLtr }" class="font-bold text-xl">
        {{ $t('report_patient') }}
        <span class="text-rose">{{ $t('report_environment') }}</span>
      </h3>
      <BackFa :text="$t('back')" />
    </div>
    <div
      :class="{ rtl: isLtr }"
      key=""
      class="flex justify-center items-center mt-5"
    >
      <div
        @click="$router.push({ name: 'Information' })"
        class="cursor-pointer text-center ml-20 h-24 w-24 bg-purple-300 rounded-full"
      >
        <h3
          class="break-words pt-2 mt-4 mx-auto font-black border border-white text-white rounded-full h-16 w-16"
        >
          {{ $t('health_record') }}
        </h3>
      </div>
      <router-link :to="{ name: 'BedRoom' }">
        <img
          width="350"
          :src="require(`../assets/images/${image}.png`)"
          alt=""
        />
      </router-link>
      <router-link :to="{ name: 'LivingRoom' }">
        <img
          class="mr-10"
          width="330"
          :src="require(`../assets/images/${image2}.png`)"
          alt=""
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import BackFa from '../components/back/BackFa.vue'

export default {
  name: 'PatientEnvironment',
  components: { BackFa },
  computed: {
    image() {
      return this.isRtl ? 'room2' : 'room-fr'
    },
    image2() {
      return this.isRtl ? 'room' : 'room2-fr'
    },
  },
}
</script>

<style></style>
